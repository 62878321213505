/* Dashboard.css */
 .container {
    background-color: rgb(248, 249, 250);
    height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header {
    background-color: #319197;
    color: white;
    padding: 20px;
    text-align: center;
    width: 250px;
    border-radius: 8px;
  }

  .left-content{
    text-align: left;
    width: 50%;
  }

  .right-content{
    text-align: right;
    width: 50%;
  }

  .margin-title{
    font-size: 12px;
  }
  
  .margin-text {
    font-size: 15px;
    font-weight: bold;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
  
  .button.deposit {
    color: #007bff;
  }
  
  .button.withdraw {
    color: #dc3545;
  }
  
  .list {
    width: 100%;
    padding: 0;
    list-style-type: none;
  }
  
  .list-item {
    background-color: white;
    margin: 10px 0;
    border-radius: 8px;
    box-shadow: none;  /* 0px 0px 10px rgba(0, 0, 0, 0.1); */
    padding: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .list-item-icon {
    margin-right: 15px;
  }
  
  .list-item-text {
    font-size: 15px;
  }

  .list-item-link{
    text-decoration: none !important;
    color: black !important;
  }
  