.nav-action .MuiBottomNavigationAction-label {
    opacity: 1 !important;
    padding-top: 2px;
    padding-bottom:10px;
    font-weight:600;
    padding-bottom: 15px;
    color:black;
    font-size: 11px !important;
  }
  
  .nav-action.Mui-selected .MuiBottomNavigationAction-label {
    font-weight: bold;
    padding-bottom: 5px;
  }

  .MuiBottomNavigationAction-iconOnly{
    color: #000000 !important;
  }
  
  .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
    color: #319197 !important;
  }

  .css-sxw2zh.Mui-selected{
    color: #319197 !important;
  }