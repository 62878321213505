/* Login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f8f8;
  }
  
  .login-form {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    width: 90%;
    max-width: 380px;
    text-align: center;
    margin: 0px auto;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
    color: #1f2937;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #4b5563;
    text-align: left;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: .5px solid #d1d5db;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .login-form button {
    width: 100%;
    padding: 10px;
    background-color: #319197;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .login-form button:hover {
    background-color: #319197;
  }
  