.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.44);/*rgb(0 0 0 / 14%);*/
    backdrop-filter: blur(1px);
    z-index: 999;
}

.bottom-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 16px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .padding5{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .full-width-important{
    width: 100% !important;
    max-width: 191px;
  }
  
  .bottom-sheet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0px solid #ddd;
    padding-bottom: 8px;
    margin-bottom: 8px;
    margin-top: -8px;
  }
  
  .bottom-sheet-content {
    /* Add additional styling as needed */
  }
  
  .close-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  

.bottom-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0 0;
    z-index: 1000;
    padding: 20px;
    max-height: 80vh;
    overflow-y: auto;
  }

  .stock-expiry{
    padding: 0px 0px;
    font-size: 11px;
    color: #888;
    font-weight: 400;
  }

  .bottom-sheet-exchange {
    /* background-color: #edededa1;
    border-radius: 3px;
    font-weight: 400;
    padding: 0px 10px;
    font-size: 11px;
    color: grey;
    text-transform: uppercase;
    margin-top: 0px;
    display: table; */
    background-color: #edededa1;
    border-radius: 3px;
    font-weight: 400;
    padding: 3px 10px;
    font-size: 11px;
    color: grey;
    text-transform: uppercase;
    margin-top: 0px;
    display: inline;
    margin-right: 5px;
  }

  .bottomSheet-close-btn{
    position: absolute !important;
    right: 9px;
    top: 5px;
  }

  .bottom-sheet-scriptName{
    align-items: center;
    /* display: flex; */
    font-size: 16px !important;
  }
  
  .bottom-sheet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .lot-quantity-section{
    margin-top: 25px !important;
  }

  .center{
    padding: 0px !important;
  }

  .center p{
    text-align: center;
    font-size: .8rem !important;
    color: grey;
  }

  .right-align{
    text-align: right !important;
    font-size: 13px !important;
  }

  .left-align{
    text-align: left !important;
    font-size: 13px !important;
  }

  .green{
    color:green !important;
  }

  .red{
    color:red !important;
  }

  .css-1ex1afd-MuiTableCell-root{
    font-family: 'Lato',sans-serif !important;
    padding: 5px 16px !important;
    border-bottom: .5px solid rgba(224, 224, 224, 0.6) !important;
  }

  .css-1ygcj2i-MuiTableCell-root{
    font-family: 'Lato',sans-serif !important;
    padding: 5px 16px !important;
  }

  .css-11xur9t-MuiPaper-root-MuiTableContainer-root{
    border-radius: 0px !important;
    box-shadow: none !important;
  }

  .noborder{
    border-radius: 0px !important;
    box-shadow: none !important;
  }

  .noborder td{
    font-family: 'Lato', sans-serif !important;
    padding: 5px 16px !important;
    border-bottom: .5px solid rgba(224, 224, 224, 0.6) !important;
  }

  .input-group{
    display: flex;
  }

  .plus-minus_btn{
    background: #9499981a !important;
    border: 0 !important;
    border-radius: 4px !important;
    color: #333 !important;
    display: inline-block !important;
    font-size: 24px !important;
    height: 36px !important;
    line-height: 30px !important;
    padding: 0 !important;
    width: 36px !important;
    min-width: 36px !important;
    max-width: 36px !important;
  }

  .input-text-field{
    background: #fff !important;
    border: 1px solid #0003 !important;
    border-radius: 4px !important;
    color: #6e6b7b !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    height: 33px !important;
    line-height: 21px !important;
    margin: 0 6px !important;
    outline: 0 !important;
    text-align: center !important;
    width: 50%;
  }

  .input-group {
    display: flex;
    align-items: center;
  }
  
  .input-group .MuiIconButton-root {
    background-color: #f0f0f0;
  }
  
  .quantity-section,
  .order-type-section {
    margin-top: 16px;
  }

  .toggle-switch {
    display: flex;
    border: 0px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    /* width: -moz-fit-content; */
    width: 150px; 
    /* fit-content; */
    margin-top: 0px;
    background-color: #9e9e9e1f;
  }

  .limit-price-box{
    width: calc(100% - 20px) !important;
  }
  
  .toggle-switch span, button {
    /* padding: 4px 16px;
    cursor: pointer;
    background-color: #f0f0f0;
    font-size: 14px;
    font-family: Lato;
    font-weight: 600;
    margin-top: 2px;
    border:none; */
    padding: 7px 16px;
    cursor: pointer;
    background-color: transparent;
    font-size: 13px;
    font-family: Lato;
    font-weight: 600;
    border: none;
  }
  
  .toggle-switch .active {
    /* background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    margin: 3px;
    border-radius: 5px; */

    background-color: #319197;
    margin: 0px;
    border-radius: 0px;
    color: white;
  }

  .market-depth{
    display: flex !important;
    box-shadow: 0 0px 6px rgba(0, 0, 0, 0.08) !important;
    padding: 0px 20px !important;
    margin-left: -19px !important;
    width: calc(100% + 39px) !important;
  }

  .market-depth-title{
    text-align: center !important;
    font-size: 13px !important;
    padding: 5px !important;
    margin-top: 5px !important;
  }
  
  .stock-price {
    color: #000;
  }
  
  .stock-change {
    color: red; /* Change based on stock performance */
  }
  
  .stock-details {
    /* margin-top: 20px; */
    margin: 0px !important;
    width: calc(100% + 40px) !important;
    padding: 7px 0px !important;
    background-color: #f7f7f7;
    margin-top: -4px !important;
    margin-left: -20px !important;
  }

  .dpr{
    margin-top: 5px !important;
  }
  
  .order-depth {
    width: 50%;
    margin-top: 0px;
  }
  
  .bottom-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }

  .css-ofrcno-MuiGrid-root{
    width: 100% !important;
    margin-left: 0px !important;
  }

  .change-icon {
    width: 16px; /* Adjust size as needed */
    height: 16px; /* Adjust size as needed */
    margin-left: 5px; /* Adjust spacing as needed */
    padding-top: 4px;
  }

  .button-buy{
    background-color: #319197 !important; /* #0899811a !important; */
    color: #FFFFFF !important; /* #148814 !important; */
    box-shadow: none !important;
    padding: 6px 60px !important;
    width: 48%;
    font-weight: 600 !important;
  }

  .button-sell{
    background-color: #ff3a3a !important; /* f236451a */
    color: #FFFFFF !important; /* ff3a3a */
    box-shadow: none !important;
    padding: 6px 60px !important;
    width: 48%;
    font-weight: 600 !important;
  }

  .ohlc{
    color: black !important;
    font-weight: 600 !important;
  }
  