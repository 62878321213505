/* .add-script-container {
    padding: 20px;
  }
  
  .segment-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }
  
  .segment-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #e0e0e0;
    cursor: pointer;
  }
  
  .segment-button.active {
    background-color: #007bff;
    color: white;
  }
  
  .search-bar {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .script-list {
    max-height: 400px;
    overflow-y: auto;
  }
  
  .script-item {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
  }
  
  .script-item:hover {
    background-color: #f0f0f0;
  }
  
  .option-type-selector {
    margin: 10px 0;
  }
  
  .option-type-selector label {
    margin-right: 10px;
  }
  
  .option-type-selector select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
   */

   .option-type-buttons {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
  }
  
  .option-type-button {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
    cursor: pointer;
    flex: 1;
    margin: 0 5px;
    text-align: center;
  }
  
  .option-type-button.active {
    background-color: #319197;
    color: white;
    border-color: #319197;
  }
  
  .option-type-button:hover {
    background-color: #319197;
    color: white;
  }
  
  .segment-buttons {
    display: flex;
    justify-content: space-around;
  }
  
  .segment-button {
    padding: 10px;
    margin: 5px;
    border: none;
    background: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;
  }
  
  .segment-button.active {
    border-bottom: 2px solid #319197;
  }
  
  .search-bar {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .script-list {
    /* max-height: 400px; */
    overflow-y: auto;
  }
  
  .script-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    align-content: space-between;
    align-items: flex-end;
  }
  
  .script-item:hover {
    background-color: #f0f0f0;
  }
  

  .expiry{
    display: inline;
    font-size: 12px;
    margin-left: 7px;
    color: gray;
    flex: 1;
  }