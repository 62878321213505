/* src/Trades.css */
/* .trades-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .trades-page-wrapper {
    height: 100vh; 
    overflow-y: auto; 
  } */

  .refresh-button{
    position: fixed;
    top: 10px;
    right: 0px;
    z-index: 99999;
    background: transparent;
    border: none;
    color: #FFFFFF;
    font-weight: 500;
  }

  .trades-page-wrapper {
    height: 100vh; /* Full viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .trades-page {
    /* padding: 0px 20px 20px 20px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loader {
    text-align: center;
    padding: 20px;
  }

  .filters {
    display: flex;
    justify-content: center;
    margin: 0px auto 10px;
  }
  
  .filters button {
    background: none;
    border: 1px solid #319197;
    border-radius: 30px;
    font-size: 13px;
    margin: 0 10px;
    padding: 4px 16px;
    cursor: pointer;
    line-height: 18px;
    font-weight: 400;
  }
  
  .filters button.active {
    background-color: #319197;
    color: white;
    border-radius: 30px;
  }