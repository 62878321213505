body {
  margin: 0;
  font-family: 'Lato',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa !important;
}

code {
  font-family: 'Lato';
    background-color: #f8f9fa !important;
}


.MuiListItemText-root span{
  font-family: 'Lato',sans-serif !important;
}

.MuiTypography-root{
  font-family: 'Lato',sans-serif !important;
}

.video-container {
  position: sticky;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; /* optional: black background for better aesthetics */
  margin-bottom: 20px;
  top: 74px;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
