/* src/PositionCard.css */
.position-card {
    /* border: 1px solid #ddd; */
    margin: 5px 10px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    max-width: 600px;
    background-color: #ffffff;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.05);
  }
  
  .position-scriptName {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
  }

  .position-cfbox{
    margin: -1px 2px 0px 0px;
    height: 11px;
    line-height: 11px;
    vertical-align: middle;
  }

  .position-autocut{
    font-size: 11px;
    padding: 0px 0px;
    color: #888;
    font-weight: 400;
  }
  
  .position-dateTime {
    font-size: 11px;
    padding: 0px 0px;
    color: #888;
    font-weight: 400;
    margin-bottom: 3px;
  }
  
  .position-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .position-header h2 {
    margin: 0;
  }
  
  .position-header p {
    margin: 0;
    color: #888;
  }
  
  .position-body {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-top: 5px;
  }
  
  p {
    margin: 0px;
  }

  .green{
    color: #1f787e !important;
  }

  .red{
    color: red !important;
  }
  
  .position-quantity.green {
    background-color: #0899811a;
    color: #089981;
    font-weight: 600;
    padding: 5px 8px;
    border-radius: 3px;
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  
  .position-quantity.red {
    background-color: #f236451a;
    color: #f23645;
    font-weight: 600;
    padding: 5px 8px;
    border-radius: 3px;
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  
  .position-market {
    background-color: #2f67f01a;
    color: #2f67f0;
    border-radius: 3px;
    font-weight: 600;
    padding: 5px 8px;
    font-size: 12px;
    margin-bottom: 5px;
  }
  
  .position-exchange {
    background-color: #0000001a;
    border-radius: 3px;
    font-weight: 600;
    padding: 5px 8px;
    font-size: 12px;
    color: #333;
    margin-top: 5px;
    text-transform: uppercase;
  }
  
  .position-price {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 11px;
  }

  .position-pnl {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .position-margin{
    margin-top: 5px;
  }
  
  .position-status {
    padding: 2px 5px;
    border-radius: 3px;
    color: #fff;
  }
  
  .position-status.complete {
    background-color: #28a745;
  }
  
  .text-end {
    text-align: right !important;
  }
  
  .d-flex {
    display: flex !important;
  }
  
  .justify-content-between {
    justify-content: space-between !important;
  }
  
  .align-items-center {
    align-items: center !important;
  }
  