/* StockCard.css */

.stock-card {
    /* border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #fff; */
    margin: 5px 0px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    max-width: 600px;
    background-color: #ffffff;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.05);
  }

  .stock-scriptName{
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
  }
  
  .stock-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .stock-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .stock-header p {
    margin: 0;
    color: #888;
    font-size: 14px;
  }
  
  .stock-body {
    display: flex;
    justify-content: space-between;
  }
  
  .stock-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
  }
  
  .stock-price.red {
    /* background-color: #ffe5e5;
    color: #ff0000; */
    background-color: #f23645e0;
    /* #f236451a; */
    color: #ffffff !important;
    padding: 2px 15px;
    border-radius: 3px;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 3px;
    font-weight: 600;
  }

  .stock-HLOC{
    font-size: 12px;
    padding: 0px 0px;
    color: #888;
    font-weight: 600;
  }

  .stock-expiry{
    padding: 0px 0px;
    font-size: 11px;
    color: #888;
    font-weight: 400;
  }

  .stock-LTP{
    margin: 0px !important;
    padding: 0px;
    font-size: 14px;
    font-weight: 500;
  }

  .change-icon {
    width: 16px; /* Adjust size as needed */
    height: 16px; /* Adjust size as needed */
    margin-left: 5px; /* Adjust spacing as needed */
  }

  .stock_LTP-continer{
    align-items: center;
    display: flex;
  }

  .stock-change{
    color: #000000;
    font-size: 12px;
    font-weight: 500;
  }
  
  .stock-change.green{
    color: #089981;
    font-size: 12px;
    font-weight: 600;
  }

  .stock-change.red{
    color: #f23645;
    font-size: 12px;
    font-weight: 600;
  }
  
  .stock-price.green {
    /* background-color: #e5ffe5;
    color: #00a000; */
    background-color: #319197;
    /* #0899811a; */
    color: #ffffff !important;
    padding: 2px 15px;
    border-radius: 3px;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 3px;
    font-weight: 600;
  }

  .white{
    color: white !important;
  }
  
  .stock-price p {
    margin: 0;
    /* font-size: 16px; */
    /* font-weight: bold; */
  }
  
  .stock-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }
  
  .stock-change.red {
    color: #ff0000;
  }
  
  .stock-change.green {
    color: #00a000;
  }
  
  .stock-footer p {
    margin: 0;
    font-size: 14px;
  }
  
  .text-end{
    text-align: right !important;
  }
  
  .d-flex {
    display: flex !important;
  }

  .justify-content-between {
    justify-content: space-between !important;
  }

  .align-items-center {
    align-items: center !important;
  }
