/* StockList.css */

.stock-list {
    padding: 16px;
  }

  .stock-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  
  .stock-list h2 {
    margin-bottom: 16px;
  }
  
  .lastUpdate{
    font-size: 10px;
    text-align: right;
    color: #888;
    width: 100%;
  }

  .search-icon{
    position: fixed;
    /* top: 19px;
    right: 50px; */
    top: 10px;
    right: 35px;
    z-index: 9999;
    height: 18px;
  }

  .search-icon_2{
    display: block;
    margin: 15px auto;
    padding: 0px;

    background-color: #329198;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .tv-icon{
    position: fixed;
    top: 10px;
    right: 75px;
    z-index: 9999;
    height: 18px;
  }