/* Portfolio.css */

.portfolio-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .portfolio h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .position-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .pnl-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #e6ecf7;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    max-width: 600px;
    margin-bottom: 10px;
  }
  
  .pnl-title {
    color: #007bff;
  }
  
  .pnl-value {
    color: #333;
  }
  
  .pnl-value.negative {
    color: #dc3545; /* Red color for negative values */
  }
  
  .pnl-value.positive {
    color: #28a745; /* Green color for positive values */
  }
  