/* ChangePassword.css */
.change-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    background-color: transparent;
    margin-top: 20%;
  }
  
  .change-password-form {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  .change-password-form h2 {
    margin-bottom: 20px;
    color: #1f2937;
  }
  
  .change-password-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #4b5563;
    text-align: left;
  }
  
  .change-password-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .change-password-form button {
    width: 100%;
    padding: 10px;
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .change-password-form button:hover {
    background-color: #2563eb;
  }
  
  .change-password-form .note {
    margin-top: 15px;
    color: #9ca3af;
  }
  