/* src/TradeCard.css */
.trade-card {
    /* border: 1px solid #ddd; */
    margin: 5px 0px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    max-width: 600px;
    background-color: #ffffff;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.05);
  }

  .trade-scriptName{
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
  }

  .trade-dateTime{
    /* font-size: 12px; */
    font-size: 11px;
    color: #888;
    font-weight: 400;
    /* margin-bottom: 3px; */
  }
  
  .trade-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .trade-header h2 {
    margin: 0;
  }
  
  .trade-header p {
    margin: 0;
    color: #888;
  }
  
  .trade-body {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-top: 5px;
  }

  p{
    margin: 0px;
  }
  
  .trade-quantity.green {
    background-color: #0899811a;
    color: #089981;
    font-weight: 600;
    padding: 3px 8px;
    border-radius: 3px;
    font-size: 12px;
    margin-right: 10px;
  }
  
  .trade-quantity.red {
    background-color: #f236451a;
    color: #f23645;
    font-weight: 600;
    padding: 3px 8px;
    border-radius: 3px;
    font-size: 12px;
    margin-right: 10px;
  }

  .trade-transaction.green{
    background-color: #0899811a;
    border-radius: 3px;
    font-weight: 600;
    padding: 3px 8px;
    font-size: 12px;
    color: #089981;
    text-transform: uppercase;
  }



  .trade-transaction.red{
    background-color: #f236451a;
    border-radius: 3px;
    font-weight: 600;
    padding: 3px 8px;
    font-size: 12px;
    color: #f23645;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  
  .trade-market {
    background-color: #2f67f01a;
    color: #2f67f0;
    border-radius: 3px;
    font-weight: 600;
    padding: 3px 8px;
    font-size: 12px;
  }
  
  .trade-exchange {
    /* background-color: #0000001a; */
    border-radius: 3px;
    font-weight: 400;
    padding: 5px 0px 0px;
    font-size: 12px;
    color: #990f0f;
    text-transform: uppercase;
  }
  
  .trade-price {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  
  .trade-status {
    padding: 2px 5px;
    border-radius: 3px;
    color: #fff;
  }
  
  .trade-status.complete {
    background-color: #28a745;
  }

  .text-end{
    text-align: right !important;
  }
  
  .d-flex {
    display: flex !important;
  }

  .justify-content-between {
    justify-content: space-between !important;
  }

  .align-items-center {
    align-items: center !important;
  }